.chrome-picker {
   border-radius: 12px !important;
   font-family: 'Inter', sans-serif !important;
   width: auto !important;
   box-shadow: none !important;
   background: transparent !important;
}

.chrome-picker>:first-child {
   border: none !important;
   padding-bottom: 46% !important;
   border-radius: 12px !important;
}

.chrome-picker>:first-child>div {
   border: 12px !important;
}

.chrome-picker>:last-child>:last-child input {
   border-radius: 6px !important;
   border: 0.5px solid #F2F4F7 !important;
   box-shadow: none !important;
}

.chrome-picker>:last-child>:first-child>:last-child>:last-child>:last-child>div {
   border-radius: 6px !important
}

.chrome-picker>:last-child>:first-child>:last-child>:first-child>:first-child>:first-child {
   border-radius: 6px !important
}

.chrome-picker>:last-child>:last-child>:last-child {
   display: none !important
}

.chrome-picker>:last-child>.flexbox-fix>:first-child {
   display: none !important
}

.chrome-picker>:last-child>:first-child>:last-child>:last-child {
   display: none !important
}

.chrome-picker>:last-child>:first-child>:last-child>:first-child {
   margin-top: 8px !important
}

.chrome-picker>:last-child>:last-child {
   display: none !important
}

.chrome-picker>:last-child>:first-child>:last-child>:first-child>:first-child>:first-child>div>div {
   transform: translate(-6px, -11px) !important;
   height: 30px !important;
   box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12) !important;
   border: 1px solid #F2F4F7 !important;
   width: 14px !important;
   border-radius: 100px !important;
}

.chrome-picker>:last-child>:first-child>:last-child>:first-child>:first-child>:first-child>div {
   height: 32px !important;
   width: 16px !important;
   border-radius: 100px !important;
}

.saturation-white {
   border-radius: 8px !important
}
