.admin_textarea::placeholder {
   color: #9BA1A9;
   /* font-family: 'Inter' !important; */
}

.admin_textarea:focus {
   -webkit-appearance: none;
   /* border: 1px solid #231FFF; */
}

.admin_textarea:disabled {
   cursor: not-allowed;
}

.admin_textarea:disabled::placeholder {
   opacity: 1;
}

.admin_textarea {
   -webkit-tap-highlight-color: transparent !important;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   padding: 12px 14px;
}
