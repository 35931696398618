.switch {
   width: 36px;
   height: 20px;
}

.switch-slider {
   transition: opacity .4s, border-radius .4s;
   border-radius: 12px;
   opacity: 1;
}

.switch-circle {
   position: absolute;
   content: "";
   height: 16px;
   width: 16px;
   top: 50%;
   transform: translateY(-50%);
   transition: left .4s, opacity .4s, transform .4s;
   border-radius: 50%;
   left: 2px;
   filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10));
}


/* .switch input:focus ~ .switch-slider {
  box-shadow: 0 0 1px rgba(183, 218, 246, 0.7);
} */

.switch input:checked~.switch-circle {
   left: 18px;
   opacity: 1;
}

/* .switch input:checked ~ .switch-circle {
  box-shadow: 0px 1px 2px #616784;
} */

.switch input:disabled~.disabled {
   /* background-color: #D0D5DD; */
   cursor: auto;
}


.switch {
   -webkit-tap-highlight-color: transparent !important;
}
